<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div>
            <b-card
                :header="$t('settings.documents.group.documents')"
                header-tag="header"
            >
                <b-card-text>
                    <b-row>
                        <b-col cols="6">
                            <zw-select-group v-model="form.documents_layout_template"
                                             name="documents_layout_template"
                                             :label-prefix="labelPrefix"
                                             :options="templates"
                            ></zw-select-group>
                        </b-col>

                        <b-col cols="6">
                            <zw-select-group v-model="form.allow_order_edit"
                                             name="allow_order_edit"
                                             :label-prefix="labelPrefix"
                                             :options="{
                                            0: $t('common.button.no'),
                                            1: $t('common.button.yes'),
                                         }"
                            ></zw-select-group>
                        </b-col>

                        <b-col cols="6">
                            <zw-switch-group v-model="form.include_xml_data"
                                             name="include_xml_data"
                                             :label-prefix="labelPrefix"
                            ></zw-switch-group>
                        </b-col>

                        <b-col cols="6">
                            <zw-switch-group v-model="form.preview_guidelines"
                                             name="preview_guidelines"
                                             :label-prefix="labelPrefix"
                            ></zw-switch-group>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>

            <b-card
                :header="$t('settings.documents.group.preview')"
                header-tag="header"
            >
                <b-card-text>
                    <b-row>
                        <b-col cols="6">
                            <zw-select-group v-model="form.default_preview_enable"
                                             name="default_preview_enable"
                                             :label-prefix="labelPrefix"
                                             :options="{
                                            0: $t('common.button.no'),
                                            1: $t('common.button.yes'),
                                         }"
                            ></zw-select-group>
                        </b-col>

                        <b-col cols="6">
                            <zw-select-group v-model="form.default_preview_type"
                                             name="default_preview_type"
                                             :label-prefix="labelPrefix"
                                             :options="getDocumentTypes()"
                                             :validate="{required: form.default_preview_enable == '1'}"
                            ></zw-select-group>
                        </b-col>

                    </b-row>
                </b-card-text>
            </b-card>

            <b-card
                :header="$t('settings.documents.group.info_block')"
                header-tag="header"
                cols="12"
            >
                <b-card-text>
                    <b-row>
                        <b-col cols="6">
                            <zw-select-group v-model="form.info_block_on_top"
                                             name="info_block_on_top"
                                             :label-prefix="labelPrefix"
                                             :options="{
                                            0: $t('common.button.no'),
                                            1: $t('common.button.yes'),
                                         }"
                            ></zw-select-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-card v-for="(document,index) in getDocumentTypes()"
                                :key="index"
                                v-if="!loading"
                                class="col-12"
                        >
                            <b-card-header header-tag="header" class="p-1">
                                {{ $t('invoices.document.' + document) }}
                                <b-button variant="info"
                                          class="ml-2"
                                          size="sm"
                                          v-b-popover.hover.html="docInfo"
                                >
                                    <font-awesome-icon icon="info"/>
                                </b-button>
                            </b-card-header>
                            <b-card-text>
                                <b-row>
                                    <b-col v-if="document=='kva'" cols="3">
                                        <zw-input-group v-model="form.documents_prefixes[document]"
                                                        name="document_prefix"
                                                        :label-prefix="labelPrefix"
                                                        size="s"
                                        />
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col v-for="(margin,margin_index) in form.documents_margin_block[document]"
                                           :key="margin_index">

                                        <zw-input-group v-model="form.documents_margin_block[document][margin_index]"
                                                        :name="margin_index"
                                                        :label-prefix="labelPrefix"
                                                        size="s"
                                        ></zw-input-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="3">
                                        <zw-select-group
                                            v-model="form.documents_format_block[document]"
                                            :options="getDocumentFormats()"
                                            name="documents_format"
                                            :label-prefix="labelPrefix"
                                            :multiple="false"
                                            size="s"
                                        >
                                        </zw-select-group>
                                    </b-col>

                                    <b-col v-if="document=='confirm'" cols="3">
                                        <zw-switch-group v-model="form.confirm_include_positions"
                                                         name="include_positions"
                                                         :label-prefix="labelPrefix"
                                        ></zw-switch-group>
                                    </b-col>

                                    <b-col cols="3">
                                        <zw-switch-group v-model="form.documents_hide_categories[document]"
                                                         name="documents_hide_categories"
                                                         :label-prefix="labelPrefix"
                                        ></zw-switch-group>
                                    </b-col>
                                    <b-col cols="3">
                                        <zw-switch-group v-model="form.documents_hide_table_header[document]"
                                                         name="documents_hide_table_header"
                                                         :label-prefix="labelPrefix"
                                        ></zw-switch-group>
                                    </b-col>
                                    <b-col cols="3">
                                        <zw-input-group v-model="form.documents_table_font_size[document]"
                                                        name="documents_table_font_size"
                                                        :label-prefix="labelPrefix"
                                                        size="s"
                                        ></zw-input-group>
                                    </b-col>
                                </b-row>

                                <b-form-group :label="$t(labelPrefix+'info_blocks')">
                                    <multiselect
                                        v-model="form.documents_info_block[document]"
                                        :options="getInfoBlocks()"
                                        :multiple="true"
                                        :close-on-select="false"
                                    />
                                </b-form-group>

                                <b-form-group :label="$t(labelPrefix+'address_fields')">
                                    <multiselect
                                        v-model="form.documents_address_fields[document]"
                                        :options="getAddressFields()"
                                        :multiple="true"
                                        :close-on-select="false"
                                    />
                                </b-form-group>

                                <b-form-group :label="$t(labelPrefix+'address_fields_b2c')">
                                    <multiselect
                                        v-model="form.documents_address_fields_b2c[document]"
                                        :options="getAddressFields()"
                                        :multiple="true"
                                        :close-on-select="false"
                                    />
                                </b-form-group>

                                <b-row v-if="form.documents_columns[document]">
                                    <b-col cols="12">
                                        <b-card-header header-tag="header" class="p-0 mt-3" role="tab">
                                            <b-button block v-b-toggle="'accordion-' + document" class="p-0">
                                                {{ $t('settings.documents.label.columns') }}
                                            </b-button>
                                        </b-card-header>
                                        <b-collapse :id="'accordion-'+document" accordion="my-accordion"
                                                    role="tabpanel">
                                            <b-table-simple striped>
                                                <b-thead head-variant="info">
                                                    <b-th>{{ $t('settings.documents.label.name') }}</b-th>
                                                    <b-th>{{ $t('settings.documents.label.width') }}</b-th>
                                                    <b-th>{{ $t('settings.documents.label.enable') }}</b-th>
                                                </b-thead>
                                                <draggable
                                                    v-model="form.documents_columns[document]"
                                                    tag="tbody"
                                                    item-key="name"
                                                    :class="{[`cursor-grabbing`]: dragging === true}"
                                                    group="columns"
                                                    @start="dragging = true"
                                                    @end="dragging = false"
                                                >
                                                    <tr v-for="(column,key) in form.documents_columns[document]">
                                                        <td scope="row">{{ column.name }}</td>
                                                        <td>
                                                            <zw-input-group
                                                                class="slim_form_group"
                                                                type="number"
                                                                :disableLabel="true"
                                                                v-model="form.documents_columns[document][key]['width']"
                                                                name="width"
                                                                :label-prefix="labelPrefix"
                                                            ></zw-input-group>
                                                        </td>
                                                        <td>
                                                            <b-form-checkbox
                                                                name="enable"
                                                                v-model="form.documents_columns[document][key]['enable']"
                                                            ></b-form-checkbox>
                                                        </td>
                                                    </tr>
                                                </draggable>
                                            </b-table-simple>
                                        </b-collapse>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-card>
                    </b-row>
                </b-card-text>
            </b-card>
        </div>

        <b-row>
            <b-col sm="12" class="text-xs-center mt-2">
                <b-row align-h="end">
                    <b-col sm="6" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>

        </b-row>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import Draggable from "vuedraggable"

export default {
    name: 'SettingsGeneral',
    mixins: [commonSave],
    components: {
        Draggable
    },
    data() {
        return {
            dragging: true,
            loading: true,
            form: {},
            defaultForm: {
                documents_prefixes: {},
                documents_info_block: {},
                documents_hide_categories: {},
                documents_hide_table_header: {},
                documents_table_font_size: {},
            },
            labelPrefix: 'settings.documents.label.',
        }
    },
    methods: {
        ...mapGetters('Settings', ['getDocumentsSettings']),
        ...mapGetters('CommonData', ['getDocumentLayouts', 'getInfoBlocks', 'getAddressFields', 'getDocumentsColumns', 'getDocumentTypes', 'getDocumentFormats']),
        shown() {
            this.loading = true
            const settingsDocuments = this.$store.dispatch('Settings/fetchDocuments');

            Promise.all([settingsDocuments]).then((response) => {
                this.form = {
                    ...JSON.parse(JSON.stringify(this.defaultForm)),
                    ...JSON.parse(JSON.stringify(this.getDocumentsSettings()))
                }
                this.form.confirm_include_positions = response[0].data.confirm_include_positions

                if (!this.form.documents_info_block) {
                    this.form.documents_info_block = {}
                    this.getDocumentTypes().forEach(document => {
                        this.$set(this.form.documents_info_block, document, [])
                    })
                }

                if (!this.form.documents_hide_categories) {
                    this.form.documents_hide_categories = {}
                    this.getDocumentTypes().forEach(document => {
                        this.$set(this.form.documents_hide_categories, document, [])
                    })
                }

                if (!this.form.documents_hide_table_header) {
                    this.form.documents_hide_table_header = {}
                    this.getDocumentTypes().forEach(document => {
                        this.$set(this.form.documents_hide_table_header, document, [])
                    })
                }

                if (!this.form.documents_table_font_size) {
                    this.form.documents_table_font_size = {}
                    this.getDocumentTypes().forEach(document => {
                        this.$set(this.form.documents_table_font_size, document, [])
                    })
                }

                if (!this.form.documents_address_fields) {
                    this.form.documents_address_fields = {}
                    this.getDocumentTypes().forEach(document => {
                        this.$set(this.form.documents_address_fields, document, [])
                    })
                }
                if (!this.form.documents_address_fields_b2c) {
                    this.form.documents_address_fields_b2c = {}
                    this.getDocumentTypes().forEach(document => {
                        this.$set(this.form.documents_address_fields_b2c, document, [])
                    })
                }

                if (!this.form.documents_columns) {
                    this.form.documents_columns = []
                }
                for (const [key, value] of Object.entries(this.getDocumentsColumns())) {
                    if (!this.form.documents_columns[key]) {
                        this.form.documents_columns[key] = value
                    }
                }

                this.loading = false
            });
        },
        docInfo() {
            let imgUrl = require('./../../assets/info_docs.png')
            return '<div><a title="Zoom" target="_blank" href="' + imgUrl + '"><img class="doc_info_img" src="' + imgUrl + '"></a></div>';
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.$store.dispatch('Settings/save', this.form).then((response) => {
                        this.afterSave(response)
                    }).catch(errors => {
                        this.$refs['observer'].setErrors(errors)
                        this.showValidationError()
                    })
                } else {
                    this.showValidationError()
                }
            })
        },
        afterSave(response) {
            if (response.status == 200 || response.status == 201) {
                this.$root.showMessage(
                    this.$t('common.form.title.success'),
                    this.$t('common.toasts.success_updated'),
                    'success'
                )
                this.shown()
            }
            this.$root.afterSettingsUpdate()
        },
    },
    mounted() {
        this.shown()
    },
    computed: {
        templates() {
            return this.getDocumentLayouts().map(val => {
                return {value: val, text: this.$t('settings.documents.templates.' + val)}
            })
        },
    }
}
</script>